import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import axios from "axios";
import Helmet from 'react-helmet';

const Redirect = ({ location }) => {
	
	// useEffect(() => {
	// 	let email = JSON.parse(localStorage.getItem('sessionId'))
	// 	// console.log('session id yay',email)
	// 	sendEmail(email)
	// }, [])

	// let sendEmail = async(email) => {
	// 	try{
	// 		const response = await axios.get(`https://barcelonacodeschool.com/payment/checkout-session?sessionId=${email}`)
	// 		// console.log('yay', response)
	// 	}catch(error){
	// 		// console.log('E R R O R BOOM', error.message)
	// 	}
	// }



	   // sendEmail()

	   let redirect = () => navigate('/specials/forced-migrants-refugees-special-scholarship')

	   useEffect(() => {
	   	redirect()
	   	// setTimeout(function() { redirect() }, 7000);
	   	// let cost = localStorage.getItem('bubblegum') || 580
	   	// window.dataLayer = window.dataLayer || [];
	   	// function gtag(){dataLayer.push(arguments);}
	   	// // gtag('js', new Date());
	   	// // gtag('config', 'AW-856512071');
	   	// gtag('event', 'conversion', {
	   	// 	'send_to': 'AW-856512071/Y6DLCLmAxsoBEMestZgD',
	   	// 	'value': Number(cost),
	   	// 	'currency': 'EUR',
	   	// 	'transaction_id': ''
	   	// })
	   }, []);

	   return <div className="white">
	   <Helmet 
	   
	   	/>
	   	<h1>This page has been moved to another URL</h1>
	   	</div>
	   }





	   export default Redirect
